<template>
  <div class="order-wrap">
    <el-input
      placeholder="请输入订单号"
      v-model="id"
      @keyup.enter.native="getOrderProgress"
    >
      <el-button
        @click="getOrderProgress"
        slot="append"
        icon="el-icon-search"
      ></el-button>
    </el-input>

    <div v-if="orderInfo && hasResult">
      <el-card class="order-info" shadow="never">
        <div class="info-p">订单来源：{{ orderInfo.orderFrom }}</div>
        <div class="info-p">订单编号：{{ orderInfo.orderId }}</div>
        <div class="info-p">下单人：{{ orderInfo.userName }}</div>
        <div class="info-p">下单时间：{{ orderInfo.orderDate }}</div>
        <!-- <div class="info-p">当前进度：{{ orderInfo.progress.value }}</div> -->
      </el-card>

      <div style="height: 350px; padding-left: 5px;">
        <el-steps class="order-info" direction="vertical" :active="activeIndex">
          <el-step
            v-for="(item, index) in orderInfo.progressList"
            :key="index"
            :title="item.name"
            :description="item.desc"
          ></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderProgress } from '@/api/order';

export default {
  data() {
    return {
      id: '',
      orderInfo: null,
      activeIndex: -1,
      hasResult: true,
    };
  },
  created() {
    document.title = '订单跟踪';
  },
  mounted() {
    const id = this.$route.query.id;
    if (id) {
      this.id = id;
      this.getOrderProgress();
    }
  },
  methods: {
    getOrderProgress() {
      const self = this;
      const loading = self.$vs.loading();
      getOrderProgress({
        id: self.id,
      })
        .then((res) => {
          self.hasResult = true;
          self.orderInfo = res;
          self.activeIndex = res.progressList.findIndex((item) =>
            item.ids.includes(res.progress.id)
          );
          loading.close();
        })
        .catch((err) => {
          loading.close();
          self.hasResult = false;
          self.$message.error(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.order-wrap {
  min-height: calc(100vh - 80px);
  padding: 20px;
  box-sizing: border-box;
  max-width: 900px;
  margin: auto;
  .order-info {
    margin-top: 20px;
    .info-p {
      font-size: 14px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
